.SearchPage .studentSearch{
  margin: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.SearchPage .studentSearch .searchInput{
  width: 55vw;
}

.SearchPage .StudentContainer{
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  justify-content: center;
}

.SearchPage .StudentContainer .Student{
  background-color: #d0dee9;
  align-items: center;
  display: flex;
  padding: 0.5rem;
  width: 80%;
  justify-content: space-between;
}

.SearchPage .StudentDetails{
  display: flex;
  flex-grow: row nowrap;
  justify-content: space-around;
  gap: 0.5rem;
  margin: 2rem;
}

.SearchPage .StudentDetails .Profile{
  display: flex;
  flex-flow: column wrap;
  align-items: start;
  width: 20%;
  font-size: 0.87em;
  margin-top: 0.5rem;
}

.SearchPage .StudentDetails .TableData{
  display: flex;
  flex-flow: column wrap;
  align-items: start;
  background-color: #f6f6f6;
}

.SearchPage .StudentDetails .TableData .tableData{
  width: 55vw;
}

.SearchPage .StudentDetails .TableData table{
  margin-top: 9px;
  background-color: #ededed;
}

.SearchPage .StudentDetails .TableData table thead tr th{
  border-top: none;
}

.SearchPage .StudentDetails .TableData .ButtonGroup .ButtonItem{
  background: transparent;
  box-shadow: none ;
  color: #524a5f;
  margin: 0.2rem;
  margin-bottom: 0;
  border: none;
  border-bottom: solid 0.2rem transparent;
}

.SearchPage .StudentDetails .TableData .ButtonGroup .ButtonItemActive{
  background: transparent;
  box-shadow: none !important;
  color: #5624ad;/*#9A60FF;*/
  border-bottom: solid 0.2rem #8FFF49 ;
  border-top: none;
  border-left: none;
  border-right: none;
  margin: 0.2rem;
  margin-bottom: 0;
}

.SearchPage .StudentDetails .TableData hr{
  background-color: #9f9f9f;
  width: 100%;
  margin: 0;
}

.container-certificates{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
}

.container-certificates a{
  text-align: left;
  background: rgba(47, 105, 138, 0.22);
  margin: 0 0 10px 0;
  padding: 6px;
  vertical-align: middle;
  border-radius: 2px;
  cursor: pointer;
  width: 100%;
}

.container-certificates a:hover{
  background: #2f698a78;
}

/*
.button{
  background: transparent;
  border: none !important;
  box-shadow: none !important;
}
*/