.UserContainer .searchUser {
  display: flex;
  align-items: center;
  gap: 10px;
}

.UserContainer .searchInput {
  width: 600px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 10px;
}
  
.UserContainer .btn-generate {
  padding: 8px 20px;
  background-color: #2c73d2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 480px;
}

.UserContainer .tableUsers {
  margin-top: 20px; /* Adicione a margem que desejar */
}

.UserContainer .UserContainer .tableUsers{
  display: flex;
  flex-flow: column nowrap;
  min-width: 73%;
  padding: 0.5rem;
  justify-content: center;
  background-color: #f4f4f4;
  height: 400px;
}

.UserContainer #tableUsers {
  overflow-y: scroll;
}

.UserContainer .bt-table {
  background-color: transparent;
  border: none;
}