label {
    display: block;
    margin-top: 10px;
  }
  
  .card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
  }
  
  .card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    border-radius: 2px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  }
  
  .profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    border-radius: 50%;
  }
.navbar{
    background: #fff;
    border-bottom: 3px solid #1dc99d;
    position: fixed;
    box-shadow: 0 3px 5px rgb(0 0 0 / 31%);
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 0 2em;
}

.dropProfile{
    margin-left: auto;
}

.btn-profile{
    color: #1dc99d !important;
    border-radius: 3px !important;
    border: 0 !important;
    display: inline-block !important;
    padding: 0 6px !important;
    background: 0 0 !important;
    font-size: 1.1rem !important;
    transition: box-shadow .4s cubic-bezier(.25,.8,.25,1),background-color .4s cubic-bezier(.25,.8,.25,1) !important;
    height: 65px !important;
    line-height: 63px !important;
    margin: 0 !important;
    margin-left: auto !important;
    box-shadow: none !important;
}

.btn-profile:hover{
    background-color: rgba(158,158,158,0.2);
}

.btn-profile:focus{
    outline: 0;
}

.img-profile{
    width: 45px;
    margin-right: 8px;
    border: 2px solid #ccc;
    border-radius: 500px;
}


.btn-ambiente{
    padding: 0;
    font-size: 22px !important;
    box-shadow: none !important;
    color: #1dc99d !important;
    text-shadow: -1px 1px 0px #2d658c, 1px 1px 0px #2d658c, 1px -1px 0px #2d658c, -1px -1px 0px #2d658c;
}
.UserContainer #background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(0,0,0, 0.5);
}

.UserContainer #buttonfechar {
    position: absolute;
    top: 20px;
    left: 96%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: white;
    background-color: #2c73d2;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .UserContainer h1 {
    top: 2%;
    bottom: 86.26%;
    font-weight: 400;
    font-size: 55px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.03em;
    color: #2c73d2;
}
/* Ajustando o título*/
.UserContainer #h1cadastro {
    position: absolute;
    left: 25%;
  }

  .UserContainer #h1editar {
    position: absolute;
    left: 37%;
  }
  
  /* Ajustando a caixa com os conteudos*/
  .UserContainer .box {
    position: absolute;
    width: 600px;
    height: 550px;
    left: 50%;
    top: 53%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: white;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: none;
    border-radius: 5px;
  }
  
  /* Ajustando todos os inputs e depois só posicionando*/
  .UserContainer .formCadastro {
    position: absolute;
    width: 530px;
    height: 40px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: white;
    border: none;
    border-bottom: 1px solid black;
    font-size: 25px;
    color: black;
  }
  
  .UserContainer #nomecad {
    top: 120px;
  }

  .UserContainer #emailcad {
    top: 180px;
  }
  
  .UserContainer #cpfcad {
    top: 240px;
  }

  .UserContainer #phonecad {
    top: 300px;
  }

  .UserContainer #citycad {
    width: 265px;
    left: 28%;
    top: 360px;
  }

  .UserContainer #countrycad {
    width: 225px;
    top: 360px;
    left: 75.5%;
  }
  
  /* Ajustando a checkboxe e posicionando */
  .UserContainer #admlabel{
    position: relative;
    width: 160px;
    top: 378px;
    left: 230px;
    font-size: 20px;
    color: black;

  }  

  .UserContainer #adminput {
    -webkit-appearance: none;
            appearance: none;
    width: 20px;
    height: 20px;
    border: 1.5px solid black;
    border-radius: 45px;
    position: relative;
    top: 420px;
    left: -85px;
    cursor: pointer;
    background-color: transparent;
  }

  .UserContainer input[type="checkbox"]:checked {
    background-color: white;
  }
  
  .UserContainer input[type="checkbox"]::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: black;
    border-radius: 45px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  
  .UserContainer input[type="checkbox"]:checked::before {
    opacity: 1;
  }
  
  .UserContainer .btn-cadastro {
    position: absolute;
    width: 250px;
    height: 50px;
    font-size: 25px;
    align-items: center;
    justify-content: center;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 500px;
    box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.1);
    padding: 8px 20px;
    background-color: #2c73d2;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  
  
  .UserContainer #pcadastro {
    position: absolute;
    left: 50%;
    top: 480px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-weight: 400;
    font-size: 25px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.03em;
    color: #FF0000;
  
  }
.container-history {
    display: flex;
    justify-content: center;
}

.container-history .layout-body{
    background-color: #f9f9f9;/*#FFFFFF;*/
    display: block;
    padding: 16px;
    color: rgba(0,0,0,0.87);
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
    text-align: center;
}
.SearchPage .studentSearch{
  margin: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.SearchPage .studentSearch .searchInput{
  width: 55vw;
}

.SearchPage .StudentContainer{
  display: flex;
  flex-flow: row wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  justify-content: center;
}

.SearchPage .StudentContainer .Student{
  background-color: #d0dee9;
  align-items: center;
  display: flex;
  padding: 0.5rem;
  width: 80%;
  justify-content: space-between;
}

.SearchPage .StudentDetails{
  display: flex;
  flex-grow: row nowrap;
  justify-content: space-around;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: 2rem;
}

.SearchPage .StudentDetails .Profile{
  display: flex;
  flex-flow: column wrap;
  align-items: start;
  width: 20%;
  font-size: 0.87em;
  margin-top: 0.5rem;
}

.SearchPage .StudentDetails .TableData{
  display: flex;
  flex-flow: column wrap;
  align-items: start;
  background-color: #f6f6f6;
}

.SearchPage .StudentDetails .TableData .tableData{
  width: 55vw;
}

.SearchPage .StudentDetails .TableData table{
  margin-top: 9px;
  background-color: #ededed;
}

.SearchPage .StudentDetails .TableData table thead tr th{
  border-top: none;
}

.SearchPage .StudentDetails .TableData .ButtonGroup .ButtonItem{
  background: transparent;
  box-shadow: none ;
  color: #524a5f;
  margin: 0.2rem;
  margin-bottom: 0;
  border: none;
  border-bottom: solid 0.2rem transparent;
}

.SearchPage .StudentDetails .TableData .ButtonGroup .ButtonItemActive{
  background: transparent;
  box-shadow: none !important;
  color: #5624ad;/*#9A60FF;*/
  border-bottom: solid 0.2rem #8FFF49 ;
  border-top: none;
  border-left: none;
  border-right: none;
  margin: 0.2rem;
  margin-bottom: 0;
}

.SearchPage .StudentDetails .TableData hr{
  background-color: #9f9f9f;
  width: 100%;
  margin: 0;
}

.container-certificates{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
}

.container-certificates a{
  text-align: left;
  background: rgba(47, 105, 138, 0.22);
  margin: 0 0 10px 0;
  padding: 6px;
  vertical-align: middle;
  border-radius: 2px;
  cursor: pointer;
  width: 100%;
}

.container-certificates a:hover{
  background: #2f698a78;
}

/*
.button{
  background: transparent;
  border: none !important;
  box-shadow: none !important;
}
*/
.ReportContainer{
  display: flex;
  flex-flow: row nowrap;
  /*min-width: 85%;*/
  width: 100%;
}

.ReportContainer .SideMenu{
  display: flex;
  flex-flow: column nowrap;
  min-width: 27%;
  padding: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.ReportContainer .SideMenu button{
  border: none;
  font-weight: 600;
  outline: 0;
}

.ReportContainer .SideMenu button:disabled{
  background: #A9A9A9;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}

.ReportContainer .SideMenu .btn-generate{
  background-color: #2D658C;
}

.ReportContainer .SideMenu .btn-download{
  background-color: #00c29a;
}

.ReportContainer .SideMenu .btn-generate:hover{
  background-color: #194c70;
}

.ReportContainer .SideMenu .btn-generate:hover,
.ReportContainer .SideMenu .btn-generate:active,
.ReportContainer .SideMenu .btn-generate:focus{
  border: none;
  outline: none;
  box-shadow: none;
}

.ReportContainer .SideMenu .btn-download:hover{
  background-color: #009174;
}

.ReportContainer .SideMenu .btn-download:hover,
.ReportContainer .SideMenu .btn-download:active,
.ReportContainer .SideMenu .btn-download:focus{
  background-color: #009174;
  border: none;
  outline: none;
  box-shadow: none;
}


.ReportContainer .MainContent{
  display: flex;
  flex-flow: column nowrap;
  min-width: 73%;
  padding: 0.5rem;
  justify-content: center;
  background-color: #f4f4f4;
}

.ReportContainer .MainContent .ag-header-group-cell-label, .ag-header-cell-label{
  justify-content: center;
}

.CardOptions{
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    box-sizing: border-box;
    position: relative;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    grid-gap: 0.6rem;
    gap: 0.6rem;
    margin-bottom: 0 !important;    
}

.CardOptions .card{
    width: 18rem;
    margin: 0 10px 30px 10px;
    padding: 0;
    width: 20vw;
    background-color: #ffffff;
}

.CardOptions .card.btn:hover{
    background-color: #ebebeb;/*#f1f1f1;*/
}

.CardOptions .card .card-header {
    background-color: #00c29a;
    height: 5.5rem;
    display: flex;
    justify-content: center;
}

.CardOptions .card-body {
    /*font-weight: 600;*/
    font-size: 0.849rem;
}

.CardOptions .card-body .card-text {
    text-align: center;
}
.UserContainer .searchUser {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.UserContainer .searchInput {
  width: 600px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 10px;
}
  
.UserContainer .btn-generate {
  padding: 8px 20px;
  background-color: #2c73d2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 480px;
}

.UserContainer .tableUsers {
  margin-top: 20px; /* Adicione a margem que desejar */
}

.UserContainer .UserContainer .tableUsers{
  display: flex;
  flex-flow: column nowrap;
  min-width: 73%;
  padding: 0.5rem;
  justify-content: center;
  background-color: #f4f4f4;
  height: 400px;
}

.UserContainer #tableUsers {
  overflow-y: scroll;
}

.UserContainer .bt-table {
  background-color: transparent;
  border: none;
}


.ProfilePage form{
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.ProfilePage form .columns h6{
  color: #656565;
}

.ProfilePage form .columns span{
  font-size: 12px;
  color: red;
}

.ProfilePage form .columns .text-new-password{
  margin-top: 4px;
}

.ProfilePage form .columns{
  text-align: left;
  margin-bottom: 1.5em;
}

.ProfilePage form .columns input{
  font-size: 17px;
  width: 28em;
  background-color: white;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 5px 10px;
  border-color: #2d658c;
  
}

.ProfilePage form button{
  width: 11em;
  margin: 10px 0 5px 0;
}

.ProfilePage form :disabled{
  cursor: not-allowed;
  pointer-events: all;
}



#body {
    background-color: #194863;
    padding-top: 100px;
}
