.ReportContainer{
  display: flex;
  flex-flow: row nowrap;
  /*min-width: 85%;*/
  width: 100%;
}

.ReportContainer .SideMenu{
  display: flex;
  flex-flow: column nowrap;
  min-width: 27%;
  padding: 0.5rem;
  gap: 0.5rem;
}

.ReportContainer .SideMenu button{
  border: none;
  font-weight: 600;
  outline: 0;
}

.ReportContainer .SideMenu button:disabled{
  background: #A9A9A9;
  transform: translateY(1px);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}

.ReportContainer .SideMenu .btn-generate{
  background-color: #2D658C;
}

.ReportContainer .SideMenu .btn-download{
  background-color: #00c29a;
}

.ReportContainer .SideMenu .btn-generate:hover{
  background-color: #194c70;
}

.ReportContainer .SideMenu .btn-generate:hover,
.ReportContainer .SideMenu .btn-generate:active,
.ReportContainer .SideMenu .btn-generate:focus{
  border: none;
  outline: none;
  box-shadow: none;
}

.ReportContainer .SideMenu .btn-download:hover{
  background-color: #009174;
}

.ReportContainer .SideMenu .btn-download:hover,
.ReportContainer .SideMenu .btn-download:active,
.ReportContainer .SideMenu .btn-download:focus{
  background-color: #009174;
  border: none;
  outline: none;
  box-shadow: none;
}


.ReportContainer .MainContent{
  display: flex;
  flex-flow: column nowrap;
  min-width: 73%;
  padding: 0.5rem;
  justify-content: center;
  background-color: #f4f4f4;
}

.ReportContainer .MainContent .ag-header-group-cell-label, .ag-header-cell-label{
  justify-content: center;
}
