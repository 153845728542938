.navbar{
    background: #fff;
    border-bottom: 3px solid #1dc99d;
    position: fixed;
    box-shadow: 0 3px 5px rgb(0 0 0 / 31%);
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 0 2em;
}

.dropProfile{
    margin-left: auto;
}

.btn-profile{
    color: #1dc99d !important;
    border-radius: 3px !important;
    border: 0 !important;
    display: inline-block !important;
    padding: 0 6px !important;
    background: 0 0 !important;
    font-size: 1.1rem !important;
    transition: box-shadow .4s cubic-bezier(.25,.8,.25,1),background-color .4s cubic-bezier(.25,.8,.25,1) !important;
    height: 65px !important;
    line-height: 63px !important;
    margin: 0 !important;
    margin-left: auto !important;
    box-shadow: none !important;
}

.btn-profile:hover{
    background-color: rgba(158,158,158,0.2);
}

.btn-profile:focus{
    outline: 0;
}

.img-profile{
    width: 45px;
    margin-right: 8px;
    border: 2px solid #ccc;
    border-radius: 500px;
}


.btn-ambiente{
    padding: 0;
    font-size: 22px !important;
    box-shadow: none !important;
    color: #1dc99d !important;
    text-shadow: -1px 1px 0px #2d658c, 1px 1px 0px #2d658c, 1px -1px 0px #2d658c, -1px -1px 0px #2d658c;
}