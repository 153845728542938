.container-history {
    display: flex;
    justify-content: center;
}

.container-history .layout-body{
    background-color: #f9f9f9;/*#FFFFFF;*/
    display: block;
    padding: 16px;
    color: rgba(0,0,0,0.87);
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
    text-align: center;
}