.CardOptions{
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    box-sizing: border-box;
    position: relative;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    gap: 0.6rem;
    margin-bottom: 0 !important;    
}

.CardOptions .card{
    width: 18rem;
    margin: 0 10px 30px 10px;
    padding: 0;
    width: 20vw;
    background-color: #ffffff;
}

.CardOptions .card.btn:hover{
    background-color: #ebebeb;/*#f1f1f1;*/
}

.CardOptions .card .card-header {
    background-color: #00c29a;
    height: 5.5rem;
    display: flex;
    justify-content: center;
}

.CardOptions .card-body {
    /*font-weight: 600;*/
    font-size: 0.849rem;
}

.CardOptions .card-body .card-text {
    text-align: center;
}