.UserContainer #background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(0,0,0, 0.5);
}

.UserContainer #buttonfechar {
    position: absolute;
    top: 20px;
    left: 96%;
    transform: translate(-50%, -50%);
    color: white;
    background-color: #2c73d2;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .UserContainer h1 {
    top: 2%;
    bottom: 86.26%;
    font-weight: 400;
    font-size: 55px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.03em;
    color: #2c73d2;
}
/* Ajustando o título*/
.UserContainer #h1cadastro {
    position: absolute;
    left: 25%;
  }

  .UserContainer #h1editar {
    position: absolute;
    left: 37%;
  }
  
  /* Ajustando a caixa com os conteudos*/
  .UserContainer .box {
    position: absolute;
    width: 600px;
    height: 550px;
    left: 50%;
    top: 53%;
    transform: translate(-50%, -50%);
    background-color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: none;
    border-radius: 5px;
  }
  
  /* Ajustando todos os inputs e depois só posicionando*/
  .UserContainer .formCadastro {
    position: absolute;
    width: 530px;
    height: 40px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: none;
    border-bottom: 1px solid black;
    font-size: 25px;
    color: black;
  }
  
  .UserContainer #nomecad {
    top: 120px;
  }

  .UserContainer #emailcad {
    top: 180px;
  }
  
  .UserContainer #cpfcad {
    top: 240px;
  }

  .UserContainer #phonecad {
    top: 300px;
  }

  .UserContainer #citycad {
    width: 265px;
    left: 28%;
    top: 360px;
  }

  .UserContainer #countrycad {
    width: 225px;
    top: 360px;
    left: 75.5%;
  }
  
  /* Ajustando a checkboxe e posicionando */
  .UserContainer #admlabel{
    position: relative;
    width: 160px;
    top: 378px;
    left: 230px;
    font-size: 20px;
    color: black;

  }  

  .UserContainer #adminput {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1.5px solid black;
    border-radius: 45px;
    position: relative;
    top: 420px;
    left: -85px;
    cursor: pointer;
    background-color: transparent;
  }

  .UserContainer input[type="checkbox"]:checked {
    background-color: white;
  }
  
  .UserContainer input[type="checkbox"]::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: black;
    border-radius: 45px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  
  .UserContainer input[type="checkbox"]:checked::before {
    opacity: 1;
  }
  
  .UserContainer .btn-cadastro {
    position: absolute;
    width: 250px;
    height: 50px;
    font-size: 25px;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 500px;
    box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.1);
    padding: 8px 20px;
    background-color: #2c73d2;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  
  
  .UserContainer #pcadastro {
    position: absolute;
    left: 50%;
    top: 480px;
    transform: translate(-50%, -50%);
    font-weight: 400;
    font-size: 25px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.03em;
    color: #FF0000;
  
  }