

.ProfilePage form{
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.ProfilePage form .columns h6{
  color: #656565;
}

.ProfilePage form .columns span{
  font-size: 12px;
  color: red;
}

.ProfilePage form .columns .text-new-password{
  margin-top: 4px;
}

.ProfilePage form .columns{
  text-align: left;
  margin-bottom: 1.5em;
}

.ProfilePage form .columns input{
  font-size: 17px;
  width: 28em;
  background-color: white;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 5px 10px;
  border-color: #2d658c;
  
}

.ProfilePage form button{
  width: 11em;
  margin: 10px 0 5px 0;
}

.ProfilePage form :disabled{
  cursor: not-allowed;
  pointer-events: all;
}


